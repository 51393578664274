import React from 'react';
import AdminNav from './AdminNav';

function AdminManageCoordinator() {
  return (
    <div>
    
    <AdminNav/>
      <div className="sticky_bar">
        <p>Manage Coordinators</p>
      </div>

      <table id="table">
        <tr>
          <th>Coordinator Name</th>
          <th>Coordinator ID</th>
          <th>Email id</th>
          <th>Courses Coordinated</th>
          <th>Improvements made</th>
          <th>Action</th>
        </tr>
        <tr>
          <td>John Mcklain</td>
          <td>1075325785</td>
          <td>jxm785@mavs.uta.edu</td>
          <td>
            <a href="../services.html" target="_blank">
              <u>DATA 3402, DATA 4572</u>
            </a>
          </td>
          <td>
            xyz
            <br />
            <a href="#" target="_blank">
              <u>Proposal Tracking</u>
            </a>
          </td>
          <td>
            <a href="#">
              <u>Edit</u>
            </a>{' '}
            <a href="#">
              <u>Delete</u>
            </a>
          </td>
        </tr>
        <tr>
          <td>Linda Christian</td>
          <td>1075375785</td>
          <td>lxc785@mavs.uta.edu</td>
          <td>
            <a href="../services.html" target="_blank">
              <u>DATA 4572</u>
            </a>
          </td>
          <td>
            abc
            <br />
            <a href="#" target="_blank">
              <u>Proposal Tracking</u>
            </a>
          </td>
          <td>
            <a href="#">
              <u>Edit</u>
            </a>{' '}
            <a href="#">
              <u>Delete</u>
            </a>
          </td>
        </tr>
      </table>
   

     
    </div>
  );
}

export default AdminManageCoordinator;
