import React from 'react';
import CoordinatorNav from './CoordinatorNav';

function CoordinatorTalkToUser() {
  return (
    <div>
      <CoordinatorNav/>
      <div className="sticky_bar">
        <p>Talk to users</p>
      </div>

      <table id="table">
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Started chat</th>
          <th>Phone no</th>
        </tr>
        <tr>
          <td>John doe</td>
          <td>
            <a href="Email">
              <u>john3111@mavs.uta.edu</u>
            </a>
          </td>
          <td>
            23-12-2023 <a href=""><u>Chat</u></a>
          </td>
          <td>
            <a href="Phone">
              <u>+16824562561</u>
            </a>
          </td>
        </tr>
        <tr>
          <td>Lei greesham</td>
          <td>
            <a href="Email">
              <u>lei7643@mavs.uta.edu</u>
            </a>
          </td>
          <td>
            23-12-2023 <a href=""><u>Chat</u></a>
          </td>
          <td>
            <a href="Phone">
              <u>+16823562561</u>
            </a>
          </td>
        </tr>
      </table>

    
    </div>
  );
}

export default CoordinatorTalkToUser;
