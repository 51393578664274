import React from 'react';
import InstructorNav from './InstructorNav';

function InstructorStudentFeedbacks() {
  return (
    <div className="student-feedbacks">
     <InstructorNav/>

      <div className="other_than_student">
        <p className="page_name_qa">
          Welcome back, <span>Instructor!</span>
        </p>
      </div>
      <br/>
      <div className="sticky_bar">
        <p>Student Feedbacks</p>
      </div>

      <table id="table">
        <tr>
          <th>Student name</th>
          <th>Course</th>
          <th>Student Email</th>
          <th>Feedback</th>
        </tr>
        <tr>
          <td>User 1</td>
          <td>Course 1</td>
          <td>abac@gamil.com</td>
          <td>sdsdsadas</td>
        </tr>
        <tr>
          <td>User 1</td>
          <td>Course 1</td>
          <td>abac@gamil.com</td>
          <td>sadasfsaf</td>
        </tr>
      </table>

      <footer>
        <ul>
          <li>
            <a
              className="footer_link"
              href="https://www.instagram.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-instagram"></i>
            </a>
          </li>
          <li>
            <a
              className="footer_link"
              href="https://www.facebook.com/login.php"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-facebook"></i>
            </a>
          </li>
          <li>
            <a
              className="footer_link"
              href="https://twitter.com/?lang=en-in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-twitter"></i>
            </a>
          </li>
          <li>
            <a
              className="footer_link"
              href="https://www.linkedin.com/feed/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-linkedin"></i>
            </a>
          </li>
        </ul>
        <h1>&copy; 2023 MSDS, UTA. All rights reserved.</h1>
      </footer>
    </div>
  );
}

export default InstructorStudentFeedbacks;
