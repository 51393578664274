import React from "react";
import StudentNav from "./StudentNav";

function StudentAnalyticsPage() {
  return (
    <div>
      <StudentNav />
      <div className="sticky_bar">
        <p>Course: Analytics</p>
      </div>

      <div className="analytics_image">
        <img src="analytics.jpeg" alt="" />
      </div>
    </div>
  );
}

export default StudentAnalyticsPage;
