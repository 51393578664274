import React from 'react';
import InstructorNav from './InstructorNav';

function InstructorFiles() {
  return (
    <div className="instructor-files">
        <InstructorNav/>
      <div className="sticky_bar">
        <p>Instructor files</p>
      </div>

      <table id="table">
        <thead>
          <tr>
            <th>Course name</th>
            <th>Course ID</th>
            <th>File name</th>
            <th>File</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Python for Data Science</td>
            <td>DATA 3402</td>
            <td>Week 1-4</td>
            <td>
              <a
                href="https://www.geeksforgeeks.org/data-science-tutorial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <u>Introduction to Python and Data Analysis</u>
              </a>
            </td>
            <td>
              <a href="#">
                <u>Edit</u>
              </a>{' '}
              <a href="#">
                <u>Delete</u>
              </a>
            </td>
          </tr>
          <tr>
            <td>Python for Data Science</td>
            <td>DATA 3402</td>
            <td>Week 5-8</td>
            <td>
              <a
                href="https://www.geeksforgeeks.org/data-science-tutorial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <u>Advanced Python Techniques</u>
              </a>
            </td>
            <td>
              <a href="#">
                <u>Edit</u>
              </a>{' '}
              <a href="#">
                <u>Delete</u>
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="sticky_bar">
        <p>Student files</p>
      </div>

      <table id="table">
        <thead>
          <tr>
            <th>Student name</th>
            <th>Student ID</th>
            <th>Course ID</th>
            <th>File</th>
            <th>Grade</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>John Mcklain</td>
            <td>1075325785</td>
            <td>DATA 3402</td>
            <td>
              <a
                href="https://www.geeksforgeeks.org/data-science-tutorial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <u>Exam 1</u>
              </a>
            </td>
            <td>B</td>
            <td>
              <a href="#">
                <u>Edit</u>
              </a>{' '}
              <a href="#">
                <u>Delete</u>
              </a>
            </td>
          </tr>
          <tr>
            <td>Agatha Princely</td>
            <td>1054325785</td>
            <td>DATA 3402</td>
            <td>
              <a
                href="https://www.geeksforgeeks.org/data-science-tutorial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <u>Exam 2</u>
              </a>
            </td>
            <td>A</td>
            <td>
              <a href="#">
                <u>Edit</u>
              </a>{' '}
              <a href="#">
                <u>Delete</u>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default InstructorFiles;
