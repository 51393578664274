import React from 'react';
import QANavbar from './QANavbar';

function QAShowReports() {
  return (
    <div>
         <QANavbar/>

      <div className="sticky_bar">
        <p>Manage Reports</p>
      </div>

      <table id="table">
        <tr>
          <th>Course Name</th>
          <th>Instructor Name</th>
          <th>Created Date</th>
          <th>Student</th>
          <th>Report</th>
        </tr>
        <tr>
          <td>Physics</td>
          <td>John doe</td>
          <td>23-12-2023</td>
          <td>John doe</td>
          <td>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit.
            Quibusdam sapiente quos deserunt minima at sed libero reprehenderit
            aliquid similique magnam reiciendis dolorum eius, sit natus corporis
            expedita impedit accusantium sint?
          </td>
        </tr>
        <tr>
          <td>Physics</td>
          <td>John doe</td>
          <td>23-12-2023</td>
          <td>John doe</td>
          <td>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorum,
            id maxime quia dolore quidem maiores dolores non debitis
            perspiciatis earum numquam vitae nostrum minus, nemo ad reiciendis
            officia similique! Architecto.
          </td>
        </tr>
      </table>
    </div>
  );
}

export default QAShowReports;
