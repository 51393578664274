import React from 'react';
import AdminNav from './AdminNav';

function AdminTalkToUsers() {
  return (
    <div>
             <AdminNav/>
      <div className="sticky_bar">
        <p>Talk to users</p>
      </div>

      <table id="table">
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Started chat</th>
          <th>Phone no</th>
        </tr>
        <tr>
          <td>John doe</td>
          <td>
            <a href="mailto:john3111@mavs.uta.edu">
              <u>john3111@mavs.uta.edu</u>
            </a>
          </td>
          <td>
            23-12-2023 <a href=""><u>Chat</u></a>
          </td>
          <td>
            <a href="tel:+16824562561">
              <u>+16824562561</u>
            </a>
          </td>
        </tr>
        <tr>
          <td>Lei greesham</td>
          <td>
            <a href="mailto:lei7643@mavs.uta.edu">
              <u>lei7643@mavs.uta.edu</u>
            </a>
          </td>
          <td>
            23-12-2023 <a href=""><u>Chat</u></a>
          </td>
          <td>
            <a href="tel:+16823562561">
              <u>+16823562561</u>
            </a>
          </td>
        </tr>
      </table>

   
      
    </div>
  );
}

export default AdminTalkToUsers;
