import React from 'react';
import InstructorNav from './InstructorNav';

function InstructorStudentDetails() {
  return (
    <div className="student-details">
       <InstructorNav/>

      <div className="sticky_bar">
        <p>Details of Students</p>
      </div>

      <table id="table">
        <tr>
          <th>Student Name</th>
          <th>ID</th>
          <th>Email</th>
          <th>Subject</th>
          <th>Report</th>
          <th>Grade</th>
        </tr>
        <tr>
          <td>Alex</td>
          <td>1002089343</td>
          <td>axy9343@mavs.uta.edu</td>
          <td>DATA 3402</td>
          <td>
            <a href="#">
              <u>click here</u>
            </a>
          </td>
          <td>B</td>
        </tr>
        <tr>
          <td>Jasmine</td>
          <td>1072383313</td>
          <td>jxi3313@mavs.uta.edu</td>
          <td>DATA 3402</td>
          <td>
            <a href="#">
              <u>click here</u>
            </a>
          </td>
          <td>A</td>
        </tr>
      </table>

    
    </div>
  );
}

export default InstructorStudentDetails;
