import React from 'react';
import AdminNav from './AdminNav';

function AdminManageQA() {
  return (
    <div>
           <AdminNav/>

      <div className="sticky_bar">
        <p>Manage QAs</p>
      </div>

      <table id="table">
        <tr>
          <th>QA Name</th>
          <th>QA ID</th>
          <th>Email id</th>
          <th>Contributed Programs</th>
          <th>Result</th>
          <th>Action</th>
        </tr>
        <tr>
          <td>John Mcklain</td>
          <td>1075325785</td>
          <td>jxm785@mavs.uta.edu</td>
          <td>
            <a href="../services.html" target="_blank">
              <u>DATA SCIENCE</u>
            </a>
          </td>
          <td>
            Placements increased by 30%{' '}
            <br />
            <a href="#" target="_blank">
              <u>Contributions Tracking</u>
            </a>
          </td>
          <td>
            <a href="#">
              <u>Edit</u>
            </a>{' '}
            <a href="#">
              <u>Delete</u>
            </a>
          </td>
        </tr>
        <tr>
          <td>Linda Christian</td>
          <td>1075375785</td>
          <td>lxc785@mavs.uta.edu</td>
          <td>
            <a href="../services.html" target="_blank">
              <u>DATA SCIENCE</u>
            </a>
          </td>
          <td>
            Student Satisfaction with the program is increased.
            <br />
            <a href="#" target="_blank">
              <u>Contributions Tracking</u>
            </a>
          </td>
          <td>
            <a href="#">
              <u>Edit</u>
            </a>{' '}
            <a href="#">
              <u>Delete</u>
            </a>
          </td>
        </tr>
      </table>
      <br /><br />

    </div>
  );
}

export default AdminManageQA;
