import React from 'react';
import AdminNav from './AdminNav';

function AdminManageStudents() {
  return (
    <div>
             <AdminNav/>

      <div className="sticky_bar">
        <p>Manage Students</p>
      </div>

      <table id="table">
        <tr>
          <th>Student name</th>
          <th>Student ID</th>
          <th>Course ID</th>
          <th>Report</th>
          <th>Grade</th>
          <th>Action</th>
        </tr>
        <tr>
          <td>John Mcklain</td>
          <td>1075325785</td>
          <td>DATA 3402, DATA 4572</td>
          <td>
            <a href="#" target="_blank">
              <u>Progress Tracking</u>
            </a>
          </td>
          <td>B</td>
          <td>
            <a href="#">
              <u>Edit</u>
            </a>{' '}
            <a href="#">
              <u>Delete</u>
            </a>
          </td>
        </tr>
        <tr>
          <td>Agatha Princely</td>
          <td>1054325785</td>
          <td>DATA 3402, DATA 3892</td>
          <td>
            <a href="#" target="_blank">
              <u>Progress Tracking</u>
            </a>
          </td>
          <td>A</td>
          <td>
            <a href="#">
              <u>Edit</u>
            </a>{' '}
            <a href="#">
              <u>Delete</u>
            </a>
          </td>
        </tr>
      </table>
      <br /><br /><br />

   
    </div>
  );
}

export default AdminManageStudents;
